import { Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: 'app-action-bar-left-content',
  host: { class: 'app-ActionBar-content-left' },
})
export class ActionBarLeftContent {}

@Directive({
  selector: 'app-action-bar-center-content',
  host: { class: 'app-ActionBar-content-center' },
})
export class ActionBarCenterContent {}

@Directive({
  selector: 'app-action-bar-right-content',
  host: { class: 'app-ActionBar-content-right' },
})
export class ActionBarRightContent {}

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-ActionBar' },
})
export class ActionBarComponent {}
