import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AccountStatus } from '../../enums/account-status.enum';
@Component({
  moduleId: module.id,
  selector: 'status-cell-renderer',
  templateUrl: 'status-cell-renderer.component.html',
  styleUrls: ['status-cell-renderer.component.scss'],
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  status: string;

  agInit(params: any): void {
    this.status = params.value;
  }

  getClass(status: string): string {
    switch (true) {
      case status && status.includes(AccountStatus.OPEN):
        return 'open';
      case status && status.includes(AccountStatus.LOCKED):
        return 'locked';
      default:
        return status;
    }
  }

  refresh(): boolean {
    return true;
  }
}
