import { Component, OnInit } from '@angular/core';
import {
  ApiMetadataApiService,
  ExadataUser,
  ListExadataUserByEmailAddressPath,
  ListExadataUserByEmailAddressResp,
} from '@xpo-ltl/sdk-apimetadata';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community';
import moment from 'moment';
import { StatusCellRendererComponent } from './cell-renderers/status-cell-renderer/status-cell-renderer.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  columnDefs: ColDef[];
  gridOptions: GridOptions;
  user: User;
  private timeFormat = 'MM/DD/YYYY';
  rowData: ExadataUser[];

  constructor(private apiMetadataService: ApiMetadataApiService, private xpoAuthService: XpoAuthenticationService) {}

  ngOnInit(): void {
    this.columnDefs = this.setColDefs();
    this.gridOptions = this.setGridOptions();
    this.apiMetadataService.listExadataUserByEmailAddress().subscribe((response: ListExadataUserByEmailAddressResp) => {
      this.rowData = response.exadataUser;
    });
  }

  private setGridOptions(): GridOptions {
    return {
      defaultColDef: {
        sortable: true,
      },
      domLayout: 'autoHeight',
      suppressCellSelection: true,
      onGridReady: (params) => {
        params.api.sizeColumnsToFit();
      },
    } as GridOptions;
  }

  private setColDefs(): ColDef[] {
    return [
      {
        valueGetter: (params: ValueGetterParams) => {
          return params.node.rowIndex + 1;
        },
        minWidth: 30,
        maxWidth: 30,
        cellClass: 'index-cell',
        headerClass: 'index-cell-header',
        editable: false,
        sortable: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRendererFramework: StatusCellRendererComponent,
      },
      {
        headerName: 'Database',
        field: 'databaseCd',
      },
      {
        headerName: 'Username',
        minWidth: 300,
        field: 'userName',
      },
      {
        headerName: 'Locked On',
        field: 'lockoutDate',
        valueFormatter: (params) => {
          return params.value ? moment(params.value).format(this.timeFormat) : null;
        },
      },
      {
        headerName: 'Expire On',
        field: 'expiryDate',
        valueFormatter: (params) => {
          return params.value ? moment(params.value).format(this.timeFormat) : null;
        },
      },
      {
        headerName: 'Created On',
        field: 'createDate',
        valueFormatter: (params) => {
          return params.value ? moment(params.value).format(this.timeFormat) : null;
        },
      },
      {
        headerName: 'Last Logged On',
        field: 'lastLogonDate',
        valueFormatter: (params) => {
          return params.value ? moment(params.value).format(this.timeFormat) : null;
        },
      },
    ] as ColDef[];
  }
}
