import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader/dynamic-script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title: string;
  build: string;
  routes: XpoShellRoute[];

  constructor(
    private configManagerService: ConfigManagerService,
    private dialog: MatDialog,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private authService: XpoLtlAuthenticationService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);

    this.authService.initAuthSetup$(region).subscribe((token) => {});

    this.routes = [
      {
        label: 'Accounts',
        path: `/${AppRoutes.ACCOUNTS}`,
      },
      {
        label: 'Password Reset',
        path: `/${AppRoutes.PWD_RESET}`,
      },
    ];

    /** Shell setup */
    this.title = 'PWD-Manager';
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  private initializeDynatrace(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${_toLower(this.configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${_toLower(region)}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        _invoke(window['dtrum'], 'identifyUser', !_isEmpty(u.emailAddress) ? u.emailAddress : u.userId);
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            setIdentity(user);
          });
      }
    });
  }
}
